<template>
  <div class="service-category-setting">
    <PageTitle title="全館優惠設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <el-input
        v-model="nameSearch"
        clearable
        placeholder="輸入活動名稱"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </el-input>

      <!-- <el-select
          v-model="isEnable"
          placeholder="是否啟用"
          value-key="value"
          clearable
          @clear="refresh"
          @change="refresh"
        >
          <el-option
            v-for="type in [
              { value: true, label: '啟用' },
              { value: false, label: '未啟用' },
            ]"
            :key="type.value"
            :label="type.label"
            :value="type"
          />
        </el-select> -->
    </FiltersContainer>

    <section>
      <el-table
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />

        <el-table-column prop="name" label="名稱" align="center" />
        <el-table-column prop="status" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="isActived(scope.row) ? 'action' : 'info'">
              {{ isActived(scope.row) ? '進行中' : '未上架' }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column prop="startAt" label="開始日期" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.startAt) }}
          </template>
        </el-table-column>
        <el-table-column prop="endAt" label="結束日期" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.endAt) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), (selectRow = scope.row), findActivity()"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <section>
        <el-form
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <el-form-item label="名稱" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="請輸入名稱"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="活動期間" prop="dateTime">
            <el-date-picker
              v-model="formData.dateTime"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              range-separator="至"
              start-placeholder="開始時間日期"
              end-placeholder="結束時間日期"
            />
          </el-form-item>

          <el-form-item label="優惠條件" prop="promoRequirements">
            <el-select
              v-model="formData.promoRequirements"
              placeholder="請選擇"
              value-key="name"
              @change="formData.req = ''"
            >
              <el-option
                v-for="req in Object.keys(promoReq)"
                v-show="promoReqDict(req).storewide"
                :key="req"
                :label="promoReqDict(req).name"
                :value="promoReqDict(req)"
              />
            </el-select>
          </el-form-item>

          <div v-if="formData.promoRequirements !== ''">
            <el-form-item
              :label="
                formData.promoRequirements.type === 'quantity' ? '數量' : '金額'
              "
              prop="req"
            >
              <el-input v-model="formData.req" placeholder="請輸入" />
            </el-form-item>
          </div>

          <el-form-item label="優惠內容" prop="promoContents">
            <el-select
              v-model="formData.promoContents"
              placeholder="請選擇"
              value-key="name"
              @change="
                ;(formData.contentPercent = ''), (formData.contentPrice = '')
              "
            >
              <el-option
                v-for="req in Object.keys(promoContent)"
                v-show="promoContentDict(req).storewide"
                :key="req"
                :label="promoContentDict(req).name"
                :value="promoContentDict(req)"
              />
            </el-select>
          </el-form-item>

          <div v-if="formData.promoContents !== ''">
            <el-form-item
              v-if="formData.promoContents.type === 'percent'"
              label="打折"
              prop="contentPercent"
            >
              <el-input
                v-model="formData.contentPercent"
                placeholder="請輸入比例 例: 0.9 為打9折"
              />
            </el-form-item>
            <el-form-item
              v-if="formData.promoContents.type === 'price'"
              label="金額"
              prop="contentPrice"
            >
              <el-input
                v-model="formData.contentPrice"
                placeholder="請輸入金額"
              />
            </el-form-item>
          </div>
        </el-form>
      </section>

      <div slot="footer">
        <el-button plain @click=";(showDialog = false), resetForm()">
          返回
        </el-button>
        <el-button type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteActivity(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import MixinFunc from '@/components/MixinFunc.vue'

import {
  CreateActivity,
  GetActivity,
  FindActivity,
  GetActivityCount,
  UpdateActivity,
  DeleteActivity,
} from '@/api/ecommerce/activity'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { promoReqConfig, promoContentConfig } from '@/config/ecommerce'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import dayjs from '@/lib/dayjs'

export default {
  name: 'ServiceCategorySetting',
  components: {
    DeleteDialog,
    EmptyBlock,
  },
  mixins: [MixinFunc],
  data: () => ({
    loading: false,
    nameSearch: '',
    isEnable: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    promoReq: promoReqConfig,
    promoContent: promoContentConfig,

    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,

    formData: {
      name: '',
      id: '',
      dateTime: '',
      enable: true,
      requirementLogic: 'OR',
      promoRequirements: '',
      req: '',
      promoContents: '',
      contentPercent: '',
      contentPrice: '',
    },

    formRules: {
      name: [noEmptyRules()],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
      dateTime: [noEmptyRules()],
      promoContents: [noEmptyRules()],
      promoRequirements: [noEmptyRules()],
      req: [noEmptyRules(), isDigitRules(), rangeRules()],
      contentPercent: [noEmptyRules(), isDigitRules(), rangeRules(0, 1)],
      contentPrice: [noEmptyRules(), isDigitRules(), rangeRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增全館優惠',
        update: '編輯全館優惠',
      })
    },

    requirement () {
      const form = this.formData
      return [
        {
          name: form.promoRequirements.name,
          type: form.promoRequirements.value,
          value: form.req,
        },
      ]
    },

    content () {
      const form = this.formData
      return [
        {
          name: form.promoContents.name,
          type: form.promoContents.value,
          value:
            form.promoContents.type === 'price'
              ? parseFloat(Number(form.contentPrice).toFixed(2))
              : parseFloat(Number(form.contentPercent).toFixed(2)),
        },
      ]
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    isActived (row) {
      const start = dayjs(row.startAt)
      const end = dayjs(row.endAt)
      const today = dayjs(new Date())

      if (today.isAfter(start) && today.isBefore(end)) return true
      if (today.isBefore(start) || today.isAfter(end)) return false
    },

    tagType (val) {
      let type = 'info'
      if (val) type = 'success'
      if (!val) type = 'danger'
      return type
    },

    promoReqDict (req) {
      return promoReqConfig[req]
    },
    promoContentDict (content) {
      return promoContentConfig[content]
    },

    async refresh () {
      this.loading = true
      await this.getActivity()
      await this.getActivityCount()
      this.loading = false
    },

    syncFormData (res) {
      const data = JSON.parse(JSON.stringify(res))
      const req = data.EcommercePromoRequirements[0]
      const content = data.EcommercePromoContents[0]
      this.formData.name = data.name
      this.formData.enable = data.enable
      this.formData.dateTime = [data.startAt, data.endAt]
      this.formData.promoRequirements = this.promoReqDict(req.type)
      this.formData.promoContents = this.promoContentDict(content.type)
      this.formData.req = req.value
      const contentType = this.promoContentDict(content.type).type
      if (contentType === 'percent') { this.formData.contentPercent = content.value } else if (contentType === 'price') { this.formData.contentPrice = content.value }
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        id: '',
        enable: true,
        requirementLogic: 'OR',
        dateTime: '',
        promoRequirements: '',
        req: '',
        promoContents: '',
        contentPercent: '',
        contentPrice: '',
      }
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createActivity()
      if (type === 'update') this.updateActivity()
      this.showDialog = false
    },

    //= > 取得優惠活動
    async getActivity () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetActivity({
        shopId: this.shop,
        start: startIndex,
        limit,
        isStorewideActivity: true,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        enable: this.isEnable === '' ? undefined : this.isEnable.value,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    //= > 創建優惠活動
    async createActivity () {
      this.loading = true
      const [res, error] = await CreateActivity({
        shopId: this.shop,
        name: this.formData.name,
        startAt: this.formData.dateTime[0],
        endAt: this.formData.dateTime[1],
        requirementLogic: this.formData.requirementLogic,
        isStorewideActivity: true,
        promoRequirements: this.requirement,
        promoContents: this.content,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('新增成功')
    },

    async findActivity () {
      const [res, error] = await FindActivity({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
      this.syncFormData(res)
    },

    //= > 刪除優惠活動
    async deleteActivity () {
      this.loading = true
      const [res, error] = await DeleteActivity({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    //= > 更新優惠活動
    async updateActivity () {
      this.loading = true
      const [, error] = await UpdateActivity({
        shopId: this.shop,
        id: this.selectRow.id,
        name: this.formData.name,
        startAt: this.formData.dateTime[0],
        endAt: this.formData.dateTime[1],
        requirementLogic: this.formData.requirementLogic,
        isStorewideActivity: true,
        promoRequirements: this.requirement,
        promoContents: this.content,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('更新成功')
    },

    //= > 取得全館活動總數
    async getActivityCount () {
      this.loading = true
      const [res, error] = await GetActivityCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        isStorewideActivity: true,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-date-editor {
  @apply w-full;
}
</style>
